import React, { Component } from 'react'
import Progress from 'react-progress';

import 'bootstrap/dist/css/bootstrap.css'

interface PopupProps {
    links: string[],
    match: {
        params: {
            index: number
        }
    }
    history: any
}
interface PopupState {
    percent: number,
    percentTimer: any
}

class Popup extends Component<PopupProps, PopupState> {
    constructor(props: PopupProps) {
        super(props)

        this.state = {
            percent: 0,
            percentTimer : 0
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.percentTimer);
    }

    componentWillMount() {
        var timer = setInterval( ()=> this.setState({ percent: this.state.percent+5}), 300)

        this.setState({
            percentTimer: timer
        });
    }

    render() {
        var containerStyle = {
            'position': 'fixed' as 'fixed',
            'left': 0,
            'right': 0,
            'top': 0,
            'bottom': 0,
            'textAlign': 'center' as 'center',
            'zIndex': 5
        }

        var overlayStyle = {
            'position': 'fixed' as 'fixed',
            'left': 0,
            'right': 0,
            'top': 0,
            'bottom': 0,
            'width': '100%',
            'height': '100%',
            'background': '#000',
            'opacity': .7,
            'zIndex': 2
        }

        var imageStyle = {
            'maxWidth': '99%',
            'maxHeight': '99%',
        }

        var closeStyle = {
            'color': 'red',
            'fontSize': '24px',
            'backgroundColor': 'white',
            'width': '30px',
            'height': '30px',
            'zIndex': 4,
            'float': 'right' as 'right'
        }


        var img = <img 
            src={this.props.links[this.props.match.params.index]} 
            style={imageStyle}
            onClick={(e) => { e.stopPropagation(); e.nativeEvent.stopImmediatePropagation()}  }    
            onLoad={(e) => { clearInterval(this.state.percentTimer); this.setState({percent:0})}}
        />
        
        var overlay = <div style={overlayStyle}></div>
        var container = (<div style={containerStyle}  onClick={() => this.props.history.push('/')}>
            <button type="button" className="close" style={closeStyle} onClick={() => this.props.history.push('/')}>
                &times;
            </button>
        {img}</div>)

        return (
            <div>
                <Progress percent={this.state.percent} style={{'zIndex': '7'}}></Progress>
                {overlay}
                {container}
            </div>
        );
    }
}

export default Popup;