import React, { Component } from 'react'
import logo from './logo.svg';
import './App.css';
import Gallary from './Gallary';
import Popup from './Popup';

import {HashRouter, Route} from 'react-router-dom'

interface AppProps {}
interface AppState {
    links: string[]
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props)

    this.state = {
        links: [
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9348.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9356.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9358.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9359.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9362.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9364.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9365.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9369.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9370.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9375.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9376.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9377.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9378.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9379.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9380.jpg",
        "https://c0nrad-art.s3.us-east-2.amazonaws.com/art/9381.jpg"]
    }
  }

  render() {  
    return (

      <HashRouter>
        <h3>c0nrad's art</h3>
        <p>Like any of the pieces? Working on anything cool yourself? Let me know! c0nrad@c0nrad.io</p>
        <Route path="/" render={(props) => <Gallary {...props} links={this.state.links}  /> }/>
        <Route path="/i/:index" render={(props) => <Popup {...props} links={this.state.links}  /> }/>
      </HashRouter>

    );
  }
}

export default App;