import React, { Component } from 'react'
import Masonry from 'react-masonry-component';

import 'bootstrap/dist/css/bootstrap.css'


interface GallaryProps {
    links: string[]
    history: any
}

interface GallaryState {
}

var masonryOptions = {
    itemSelector: '.image-container',
    gutter: 10
}

class Gallary extends Component<GallaryProps, GallaryState> {
    constructor(props: GallaryProps) {
        super(props)

        console.log(props)
    }
  
    render() {

        var links = this.props.links.map( (v, i) => {
            var link = v.split('/art/').join("/art/small-");
            return (<span key={i} className="image-container" onClick={()=>this.props.history.push(`/i/${i}`)}> 
                <img style={{'width': '100%', 'minHeight': '200px'}}src={link} />
            </span>)
        })

        return (
            <div>
                <Masonry options={masonryOptions}>
                    {links}
                </Masonry>
            </div>
        );
    }
}

export default Gallary;